.portfolio {
  width: 100%;
  padding: 2% 5%;
  border-bottom: 2px solid #ddd;
  border-top: 2px solid #ddd;
}

.portfolio-content {
  text-align: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
}

.portfolio-slider {
  position: relative;
  overflow: hidden;
  height: 90vh;
  width: 100%;
  margin: 0 auto;
}

.portfolio-slides {
  display: flex;
  transition: transform 1s ease-in-out;
  height: 100%;
}

.portfolio-slide {
  flex: 0 0 100%;
  position: relative;
}

.portfolio-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-block {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(44, 62, 80, 0.9);
  color: white;
  padding: 20px;
  text-align: left;
  box-sizing: border-box;
}

.portfolio-title {
  font-size: 46px !important;
  margin: 50px auto; /* Центрирование заголовка и добавление отступов */
  background: #2c3e50;
  color: white;
  padding: 20px 150px;
  width: fit-content; /* Ширина по содержимому */
  border-radius: 10px;
}

.text-block p {
  font-size: 26px;
  margin-bottom: 8px;
}

.text-block h3 {
  font-size: 28px;
  margin-bottom: 10px;
}

.portfolio-navigation {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  padding: 0;
  z-index: 2;
  pointer-events: none;
}

.portfolio-prev, .portfolio-next {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  z-index: 3;
  pointer-events: auto;
  position: absolute;
}

.portfolio-prev {
  left: 5px;
}

.portfolio-next {
  right: 5px;
}

.portfolio-prev img, .portfolio-next img {
  width: 32px;
  height: 32px;
}

.nav-button-background {
  background: rgba(44, 62, 80, 0.7);
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

/* Responsive Styles */
@media (max-width: 1600px) {
  .portfolio-title {
    font-size: 36px !important;
  }
  
  .text-block p {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .portfolio-slider {
    height: auto;
  }
  .portfolio-title {
    font-size: 26px !important;
  }
  .text-block p {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .portfolio {
    padding: 2% 0; /* Уменьшим отступы */
  }

  .portfolio-title {
    font-size: 24px !important;
    margin: 20px auto;
    padding: 10px 30px;
    background: #2c3e50; /* Вернем темный фон */
    color: white; /* Вернем белый текст */
  }

  .portfolio-slider {
    height: 70vh; /* Уменьшим высоту слайдера */
    padding: 0; /* Уберем отступы */
  }

  .text-block {
    padding: 15px;
  }

  .text-block p {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .portfolio-prev, .portfolio-next {
    width: 40px;
    height: 40px;
  }

  .nav-button-background {
    width: 24px;
    height: 24px;
    padding: 8px;
  }

  .portfolio-prev img, .portfolio-next img {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 480px) {
  .portfolio {
    background-color: #ffffff;
    padding: 2% 0%;
  }
  .portfolio-feature {
    margin-top: 30px;
    flex-direction: column;
    padding: 0 2%;
  }

  .portfolio-slider {
    height: 60vh; /* Еще уменьшим высоту для маленьких экранов */
  }

  .portfolio-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .text-block {
    font-size: 15px !important;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(44, 62, 80, 0.9);
    padding: 10px;
    box-sizing: border-box;
  }

  .text-block p {
    font-size: 12px;
    margin-bottom: 3px;
  }

  .text-block h3 {
    font-size: 16px;
  }

  .portfolio-title {
    font-size: 20px !important;
    margin: 15px auto;
    padding: 8px 20px;
  }

  .portfolio-content {
    padding: 0 10px;
  }

  .portfolio-prev, .portfolio-next {
    width: 32px;
    height: 32px;
  }

  .nav-button-background {
    width: 20px;
    height: 20px;
    padding: 6px;
  }

  .portfolio-prev img, .portfolio-next img {
    width: 20px;
    height: 20px;
  }

  .portfolio-prev {
    left: 2px;
  }

  .portfolio-next {
    right: 2px;
  }
}