.solar-feature {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background-color: #f4f4f4;
  margin-bottom: 20px;
  padding: 2% 10%; /* Общие отступы */
}

.solar-feature.reverse {
  flex-direction: row-reverse;
}

.solar-feature-content {
  flex: 1;
  font-size: 35px;
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 50%;
  color: #2c3e50;
  margin-left: 5%; /* Отступ между текстовым блоком и слайдером */
}

.solar-feature-content p {
  margin-bottom: 10px;
}

.solar-feature-title {
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-size: 46px;
  margin-bottom: 10px; /* Изменение на 10px */
  background: #2c3e50;
  color: white;
  padding: 20px 20px;
  border-radius: 10px;
  text-align: center;
  letter-spacing: -0.5px;
  flex-shrink: 0;
}

.solar-download-btn {
  display: flex;
  justify-content: flex-start;
  margin-top: auto;
  margin-bottom: 20px;
}

.solar-download-btn button {
  font-size: 16px;
  background: #41930b;
  color: white;
  border: none;
  padding: 15px 80px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.solar-download-btn button:hover {
  background-color: #57d100;
  transform: scale(1.04);
}

.solar-slider {
  flex: 1;
  position: relative;
  overflow: hidden;
  width: 60%;
  max-width: 60%;
  height: 90vh;
}

.solar-slides {
  display: flex;
  transition: transform 1s ease-in-out;
  height: 100%;
}

.solar-slide {
  flex: 0 0 100%;
  position: relative;
}

.solar-slide-media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.solar-slide-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(44, 62, 80, 0.9);
  color: white;
  box-sizing: border-box;
  text-align: left;
  font-size: 26px;
  z-index: 1;
  padding: 20px; /* Отступ от левого края */
}

.solar-slide-overlay h3 {
  font-size: 36px;
}

.solar-navigation {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.solar-prev, .solar-next {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
}

.solar-prev img, .solar-next img {
  width: 32px;
  height: 32px;
}

.nav-button-background {
  background: rgba(44, 62, 80, 0.7);
  border-radius: 50%;
  padding: 10px;
}

/* Адаптивность */
@media (max-width: 1624px) {
  .solar-feature-content {
    font-size: 26px;
  }
  .solar-feature-title {
    font-size: 36px;
  }
  .solar-slide-overlay h3 {
    font-size: 20px;
  }
  .solar-slide-overlay {
    font-size: 16px;
    padding: 20px; /* Отступ от левого края */
  }
}

@media (max-width: 1024px) {
  .solar-feature-content {
    font-size: 20px;
  }

  .solar-feature-title {
    font-size: 26px;
  }

  .solar-slide-overlay {
    font-size: 16px;
    padding: 20px; /* Отступ от левого края */
  }

  .solar-slide-overlay h3 {
    font-size: 20px;
  }

  .solar-download-btn button {
    font-size: 14px;
    padding: 10px 60px;
  }
}

@media (max-width: 768px) {
  .solar-feature {
    padding: 0 2%;
  }

  .solar-feature-content, .solar-slider {
    width: 100%;
    margin: 0;
  }

  .solar-slider {
    max-width: 100%;
    width: 100%;
    max-height: 350px;
  }

  .solar-feature-content {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
    font-size: 16px;
    margin-bottom: 2%;
  }

  .solar-feature-title {
    font-size: 16px;
    margin-bottom: 5px;
    background: #f4f4f4;
    color: #2c3e50;
    padding: 5px 5px;
    text-align: center;
  }

  .solar-slide-overlay {
    padding: 15px;
  }

  .solar-slide-overlay h3 {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .solar-slide-overlay p {
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 5px;
  }

  .solar-download-btn {
    margin-bottom: 10px; /* Отступ снизу */
  }

  .solar-download-btn button {
    font-size: 12px;
  }

  .solar-slide-media {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 480px) {
  .solar-feature {
    flex-direction: column-reverse;
    padding: 0 2%;
  }

  .solar-slider {
    max-width: 100%;
    width: 100%;
    max-height: 400px; /* Set a max-height for the slider */
  }

  .solar-slide-media {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .solar-slide-overlay {
    padding: 10px;
  }

  .solar-slide-overlay h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .solar-slide-overlay p {
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 3px;
  }

  .solar-feature-content {
    font-size: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .solar-feature-title {
    margin-top: 30px;
    font-size: 28px;
  }

  .solar-download-btn button {
    font-size: 10px;
    padding: 6px 40px;
  }
}
